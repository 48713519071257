import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet";
import Img from "react-cool-img";

import { Layout, PostCard, Header } from '../components/common'
// import { MetaData } from '../components/common/meta'
import signature from "../images/signature.png"

/**
* Author page (/author/:slug)
*
* Loads all posts for the requested author incl. pagination.
*
*/
const Author = ({ data, location, pageContext }) => {
    const author = data.ghostAuthor
    const posts = data.allGhostPost.edges
    const twitterUrl = author.twitter ? `https://twitter.com/${author.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = author.facebook ? `https://www.facebook.com/${author.facebook.replace(/^\//, ``)}` : null
    // console.log(data)

    return (
        <>
            <Helmet>
                <meta name="title" content={`${author.name} - Sharing knowledge is our DNA 🧬`} />
                <meta
                    name="description"
                    content="A blog where the xTalents share their knowledge and expertise about different topics and technologies. Subscribe to our newsletter and never miss an article!"
                />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://blog.x-hub.io/author/${author.slug}`} />
                <meta property="og:title" content={`${author.name} - Sharing knowledge is our DNA 🧬`} />
                <meta
                    property="og:description"
                    content="A blog where the xTalents share their knowledge and expertise about different topics and technologies. Subscribe to our newsletter and never miss an article!"
                />
                {author.profile_image ? <meta property="og:image" content={author.profile_image} /> : <meta property="og:image" content="../images/logo.svg" /> }

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={`https://blog.x-hub.io/author/${author.slug}`} />
                <meta property="twitter:title" content={`${author.name} - Sharing knowledge is our DNA 🧬`}/>
                <meta
                    property="twitter:description"
                    content="A blog where the xTalents share their knowledge and expertise about different topics and technologies. Subscribe to our newsletter and never miss an article!"
                />
                {author.profile_image ? <meta property="twitter:image" content={author.profile_image} /> : <meta property="twitter:image" content="../images/logo.svg" /> }
                <title>{author.name} - Sharing knowledge is our DNA 🧬 - xHub Blog </title>
            </Helmet>
            <Layout>
                <div className="">
                    <Header>
                    <header className="relative ">
                        <div className="px-6 lg:py-80 pb-32 pt-48 lg:w-10/12 lg:mx-auto z-30 ">
                        
                        <div className="shadow-2xl pb-28 py-56 rounded-2xl relative z-1">
                        <div className="absolute w-56 h-56 mx-auto shadow-2xl -top-20 inset-x-1/4 rounded-full">
                            <div className=" w-56 h-56 overflow-hidden ring-8 dark:ring-secondary-500 ring-white ring-offset-4 ring-offset-primary-500 rounded-full ">
                                {author.profile_image && <Img src={author.profile_image} className="transform scale-100" alt={author.name} />}
                            </div>
                        </div>
                        <div className="px-32">
                            <h1 className="general--h1-styles uppercase">{author.name}</h1>
                            {author.bio && <p className="general--p-styles mb-28">{author.bio}</p>}
                            <div className="flex justify-center space-x-4">
                                {author.website && <a rel="noopener" href={author.website} target="_blank" rel="noopener noreferrer" className="dark:text-secondary-500 dark:hover:text-primary-500 text-primary-500 hover:text-secondary-500 hover:no-underline">Website</a>}
                                {twitterUrl && <a rel="noopener" href={twitterUrl} target="_blank" rel="noopener noreferrer" className="dark:text-secondary-500 dark:hover:text-primary-500 text-primary-500 hover:text-secondary-500 hover:no-underline">Twitter</a>}
                                {facebookUrl && <a rel="noopener" href={facebookUrl} target="_blank" rel="noopener noreferrer" className="dark:text-secondary-500 dark:hover:text-primary-500 text-primary-500 hover:text-secondary-500 hover:no-underline">Facebook</a>}
                            </div>
                        </div>
                        </div>
                        
                        </div>
                    </header>
                    </Header>
                    <section className="grid lg:grid-cols-3 md:grid-cols-2 xl:gap-x-28 lg:gap-x-12 gap-x-14 gap-y-16 my-28">
                        {posts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} post={node} />
                        ))}
                    </section>
                </div>
            </Layout>
        </>
    )
}

export default Author

export const pageQuery = graphql`
    query GhostAuthorQuery($slug: String!, $limit: Int!, $skip: Int!) {
        ghostAuthor(slug: { eq: $slug }) {
            ...GhostAuthorFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {authors: {elemMatch: {slug: {eq: $slug}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        }
    }
`
